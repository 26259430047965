import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import { FaCheck } from "react-icons/fa";
import Columns from "../components/columns";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Tile from "../components/Tile";
import Container from "../components/container";
import { Link } from "gatsby";
import * as styles from "./index.module.css";

export const query = graphql`
  {
    aura: file(relativePath: { eq: "logo-aura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, layout: CONSTRAINED)
      }
    }
  }
`;

const DedicatedSolutions = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Dedicated solutions"
      headline="Improve company management"
    >
      <SEO
        title="Dedicated solutions - take care of your business"
        description="Dedicated solutions • Flexible and complementary to ERP systems for large companies, 
        niche industries or customers with non-standard needs"
        keywords={["dedicated solutions"]}
      />

      <p>
        We specialize in implementing projects that respond to the non-standard
        needs of our clients. The Aura Business system we offer is a{" "}
        <strong>very comprehensive solution</strong> that consists of many
        modules. It is a tool that can support any area of ​​your business. When
        creating our dedicated solutions, we focus on{" "}
        <strong>flexibility and numerous configuration options</strong>.
        Regardless of whether you need just a few of the available solutions or
        you need something completely new - we can provide you with it. We focus
        on adapting technology to the vision of your company's development.
      </p>
      <br />
      <Columns>
        <div>
          <h3> Aura Business </h3>
          <p>
            <strong> The Aura Business </strong> system was created to meet the
            needs of organizations expecting comprehensive solutions that will
            improve business processes. The system is flexible and complementary
            to ERP. It is a tool for sales support, marketing and service
            management. Main system modules include:
          </p>
          <p>
            <ul>
              <li>
                {" "}
                <strong> CRM </strong> – enables effective management of the
                sales process,{" "}
              </li>
              <li>
                {" "}
                <strong> E-commerce</strong> – provides a professional platform
                for self-service of business and individual clients,{" "}
              </li>
              <li>
                {" "}
                <strong> Service Management</strong> – allows you to effectively
                manage orders, complaints, customer support or the process of
                creating products according to individual orders.{" "}
              </li>
            </ul>
          </p>
          <p>
            We could talk for hours about the possibilities of this solution.
            We'd be happy to know the problems and needs of your company to
            offer you the most optimal and effective solution on the market.
          </p>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <a href="https://aurabusiness.com/">
            <GatsbyImage
              image={data.aura.childImageSharp.gatsbyImageData}
              style={{
                width: "100%",
                margin: "0 auto",
                maxWidth: 300,
              }}
              alt="Aura Business"
            />
          </a>
          <Container>
            <div
              style={{
                textAlign: "center",
                margin: "10px",
                padding: "10px",
              }}
            >
              <a className={styles.startNow} href="https://aurabusiness.com/">
                Learn more about Aura Business
              </a>
            </div>
          </Container>
        </div>
      </Columns>
      <br />
      <br />
      <BenefitBlockWrapper>
        <BenefitBlock icon={<FaCheck size="60px" />} title="Flexible" />
        <BenefitBlock
          icon={<FaCheck size="60px" />}
          title="Complementary to ERP"
        />
        <BenefitBlock icon={<FaCheck size="60px" />} title="Complex" />
      </BenefitBlockWrapper>

      <br />
      <br />

      <center>
        <h4>
          Are you interested in our dedicated solutions? Contact us. We'd be
          happy to talk about the development opportunities for your business.
        </h4>
        <Container>
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/contact/">
              Contact us
            </Link>
          </div>
        </Container>
      </center>
    </FunctionPageLayout>
  );
};

export default DedicatedSolutions;
